class TareaService {
  constructor() {}
  resources = () => ({
    tareas: {
      uri: `/v1/proyectoscalendar`,
      method: ['get']
    },
    viewacta: {
      uri: `/v1/actas/detalle`,
      method: ['get']
    },
    sendemail: {
      uri: `/v1/notificate/proyectos`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    }
  });
}

export default TareaService;
