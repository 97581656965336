class MatrizActaService {
  constructor() {}
  resources = () => ({
    matriz: {
      uri: `/v1/actas/compromisosacuerdos`,
      method: ['get']
    },
    fecharepro1: {
      uri: `/v1/actas/editarfechareprogramacion1`,
      method: ['put']
    },
    fecharepro2: {
      uri: `/v1/actas/editarfechareprogramacion2`,
      method: ['put']
    },
    fecharepro3: {
      uri: `/v1/actas/editarfechareprogramacion3`,
      method: ['put']
    },
    comentarioevidencia: {
      uri: `/v1/actas/comentarioevidencia`,
      method: ['put']
    },
    subirevidencia: {
      uri: `/v1/actas/subirevidencia`,
      method: ['post'],
      headers: { 'Content-Type': 'multipart/form-data' }
    },
    filesend: {
      uri: `/v1/actas/notificar-acta`,
      method: ['get']
    },
    descargarevidencia: {
      uri: `/v1/actas/descargarevidencia`,
      method: ['get']
    },
    exportarmatriz: {
      uri: `/v1/actas/exportarcompromisosacuerdos`,
      method: ['get']
    },
    exportar: {
      uri: `/v1/actas/exportar`,
      method: ['get']
    },
    viewacta: {
      uri: `/v1/actas/detalle`,
      method: ['get']
    },
    listaacta: {
      uri: `/v1/actas/listar`,
      method: ['get']
    },
    eliminar: {
      uri: `/v1/actas/delete`,
      method: ['delete']
    },
    responsables: {
      uri: `/v1/responsables`,
      method: ['get']
    },
    editar: {
      uri: `/v1/actas/editar`,
      method: ['put']
    },
    eliminarEntregable: {
      uri: `/v1/actas/eliminarEntregable`,
      method: ['delete']
    },
    descargaracta: {
      uri: `/v1/actas/download`,
      method: ['get']
    },
    cargarSubAreas: {
      uri: `/v1/subareas`,
      method: ['get']
    }
  });
}

export default MatrizActaService;
