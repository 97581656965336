<template>
  <div>
    <div class="mt-0 pa-0">
      <div class="w-100 d-flex align-center justify-space-between pt-2 pb-3">
        <div class="d-flex align-center">
          <v-btn fab text small color="grey darken-2" @click="prev" v-if="tipo !== 'year'">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title v-if="tipo !== 'year'">{{ title }}</v-toolbar-title>
          <v-btn fab text small color="grey darken-2" @click="next" v-if="tipo !== 'year'">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn outlined color="grey darken-2" v-on="on">
                <span>{{ typeToLabel[tipo] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="cambiarTipo('day')">
                <v-list-item-title>Días</v-list-item-title>
              </v-list-item>
              <v-list-item @click="cambiarTipo('week')">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="cambiarTipo('month')">
                <v-list-item-title>Mes</v-list-item-title>
              </v-list-item>
              <v-list-item @click="cambiarTipo('year')">
                <v-list-item-title>Año</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <v-sheet height="600" v-if="tipo !== 'year'">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :now="today"
          :type="type"
          locale="es-es"
          :event-more-text="'mostrar más'"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip v-auth-acl="'gh-cal-proy-sendnotification'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="notificar(selectedEvent)">
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>Notificar</span>
              </v-tooltip>
              <v-tooltip
                v-auth-acl="'gh-cal-proy-view'"
                v-if="
                  selectedEvent.idacta !== undefined && selectedEvent.idacta !== null && selectedEvent.idacta !== ''
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="verActa(selectedEvent)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Ver Acta</span>
              </v-tooltip>
              <v-tooltip v-auth-acl="'gh-cal-proy-generate'" v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="actaEvento(selectedEvent)">
                    <v-icon>mdi-file-document</v-icon>
                  </v-btn>
                </template>
                <span>Generar Acta</span>
              </v-tooltip>
              <v-btn icon @click="selectedOpen = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <h4>{{ 'Titulo Proyecto: ' + selectedEvent.proyecto }}</h4>
              <h4>{{ 'Titulo Etapa: ' + selectedEvent.etapa }}</h4>
              <h4>{{ 'Titulo Actividad: ' + selectedEvent.etapa }}</h4>
              <div v-if="selectedEvent.responsables != undefined ? selectedEvent.responsables.length > 0 : false">
                <div class="d-flex ma-2">
                  <v-icon class=" mr-2" small>fas fa-users</v-icon>
                  <h4 class="font-weight-bold">RESPONSABLES</h4>
                </div>
                <ul v-for="(responsables, i) in selectedEvent.responsables" :key="i">
                  <li>
                    {{ responsables.name + ' - ' + responsables.dni }}
                  </li>
                </ul>
              </div>
              <div v-if="selectedEvent.soporte != undefined ? selectedEvent.soporte.length > 0 : false">
                <div class="d-flex ma-2">
                  <v-icon class=" mr-2" small>fas fa-users</v-icon>
                  <h4 class="font-weight-bold">APOYOS</h4>
                </div>
                <ul v-for="(soporte, i) in selectedEvent.soporte" :key="i">
                  <li>
                    {{ soporte.name + ' - ' + soporte.dni }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-sheet>
      <v-sheet height="700" v-if="tipo == 'year'">
        <Calendar
          id="calendar"
          language="es"
          :data-source="events"
          @mouse-on-day="mouseOnDay"
          @mouse-out-day="mouseOutDay"
          @click-day="viewDay"
          :display-week-number="true"
          :min-date="new Date(currentYear - 1, 12, 1)"
          :max-date="new Date(currentYear, 11, 31)"
        ></Calendar>
      </v-sheet>
    </div>
    <ActasView :object="objectViewActa" :dialog="dialogActasView" @closeActasView="closeActasView"></ActasView>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import tippy, { animateFill } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/themes/light.css';
import Calendar from 'v-year-calendar';
import 'v-year-calendar/locales/v-year-calendar.es';
import TareaService from '../services/TareaService';
import ActasView from '@/giplan/actas/matriz_actas/components/ActasView';
import { notificateProyectosCalendar } from '@/services/notificationService';
import Swal from 'sweetalert2';
export default {
  name: 'TareaCalendario',
  components: {
    Calendar,
    ActasView
  },
  data: () => ({
    currentYear: new Date().getFullYear(),
    tooltip: null,
    focus: '',
    tareaService: null,
    type: 'month',
    tipo: 'month',
    today: null,
    typeToLabel: {
      month: 'Mes',
      week: 'Semana',
      day: 'Día',
      year: 'Año'
    },
    dialogPopup: false,
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      '#F44336',
      '#E91E63',
      '#9C27B0',
      '#673AB7',
      '#F44336',
      '#2196F3',
      '#03A9F4',
      '#00BCD4',
      '#009688',
      '#4CAF50',
      '#8BC34A',
      '#CDDC39',
      '#FFEB3B',
      '#FFC107',
      '#FF9800',
      '#FF5722',
      '#795548',
      '#607D8B',
      '#9E9E9E'
    ],
    dialogActasView: false,
    objectViewActa: {
      agendas: [],
      areaescribano: '',
      compromisos: [],
      escribano: '',
      invitados: [],
      idarea: '',
      idresponsable: '',
      idtrabajador: '',
      lider: '',
      nombresall: ''
    },
    showLoading: false
  }),
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return '';
      }
      switch (this.type) {
        case 'month':
          return `${this.monthFormatter(start)} del ${start.year}`;
        case 'week':
          return ` ${start.day}/${start.month}/${start.year} al ${end.day}/${end.month}/${end.year}`;
        case 'day':
          return `${start.day} de ${this.monthFormatter(start)} del ${start.year}`;
      }
      return '';
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC',
        month: 'long'
      });
    }
  },
  async mounted() {
    await this.$refs.calendar.checkChange();
    await this.cargarTareas(this.$refs.calendar.$children[0].start, this.$refs.calendar.$children[0].end);
  },
  methods: {
    tipoCambiar(tipo) {
      this.tipo = tipo;
      if (tipo != 'year') {
        this.type = tipo;
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    async cambiarTipo(tipo) {
      if (this.tipo != tipo) {
        await this.tipoCambiar(tipo);
        if (tipo != 'year') {
          await this.cargarTareas(this.$refs.calendar.$children[0].start, this.$refs.calendar.$children[0].end);
        } else {
          const fecha1 = moment().format('YYYY') + '-01' + '-01';
          const fecha2 = moment().format('YYYY') + '-12' + '-31';
          await this.cargarTareas(fecha1, fecha2);
        }
      }
    },
    //// NOTIFICACIONES /////
    async notificar(event) {
      this.showLoading = true;
      let fecha = moment(event.end);
      let fecha2 = moment();
      let dias = fecha.diff(fecha2, 'days');
      const invitados = [];
      const participantes = [];
      let invitadosNoti = [];
      let mensaje = '';

      if (dias == 0) {
        mensaje = `Te queda menos de un día en la tarea ${event.name} del proyecto ${event.proyecto} por favor actualiza tu estado`;
      }
      if (dias < 0) {
        mensaje = `Debiste subir tu entregable hace ${Math.abs(dias)} días en la tarea ${event.name} del proyecto ${
          event.proyecto
        } por favor actualiza tu estado`;
      }
      if (dias > 0) {
        mensaje = `Te quedan ${dias} días en la tarea ${event.name} del proyecto ${event.proyecto}`;
      }
      event.responsables.forEach((el) => {
        invitados.push(el.code);
        participantes.push({
          email: el.email,
          subject: 'NOTIFICACIÓN DE PROYECTO',
          content: mensaje
        });
      });
      if (event.soporte.length > 0) {
        event.soporte.forEach((el) => {
          let codigo = null;
          if (el.code != undefined) {
            codigo = el.code;
          } else {
            if (el.object.code != undefined) codigo = el.object.code;
          }
          if (codigo != null) invitados.push(codigo);
          participantes.push({
            email: el.email,
            subject: 'NOTIFICACIÓN DE PROYECTO',
            content: mensaje
          });
        });
      }
      console.log(invitados);
      invitadosNoti = new Set(invitados);
      console.log(invitadosNoti);
      for (const participant of invitadosNoti) {
        await notificateProyectosCalendar(participant, mensaje);
      }
      const res = await this.tareaService.post().execResource('sendemail', {
        participantes: participantes
      });
      this.showLoading = false;
      if (res.status) {
        await this.alertDialog('success', res.data);
      } else {
        await this.alertDialog('error', res.data);
      }
    },
    //// ACTAS /////
    closeActasView() {
      this.objectViewActa = {
        agendas: [],
        areaescribano: '',
        compromisos: [],
        escribano: '',
        invitados: [],
        idarea: '',
        idresponsable: '',
        idtrabajador: '',
        lider: '',
        nombresall: ''
      };
      this.dialogActasView = false;
    },
    actaEvento(event) {
      console.log(event);
      this.$router.push({ path: '/actas/actas-reunion', query: { i: event.compitem, r: 'T' } });
    },
    async verActa({ idacta }) {
      this.showLoading = true;
      this.objectViewActa = {
        agendas: [],
        areaescribano: '',
        compromisos: [],
        escribano: '',
        invitados: [],
        idarea: '',
        idresponsable: '',
        idtrabajador: '',
        lider: '',
        nombresall: ''
      };

      const res = await this.tareaService.get().execResource('viewacta', {
        idacta: idacta
      });
      this.showLoading = false;
      this.objectViewActa = res;
      this.dialogActasView = true;
    },
    /// ANUAL INICIO ////
    mouseOnDay: function(e) {
      if (e.events.length > 0) {
        var content = '';
        for (var i in e.events) {
          content +=
            '<div class="event-tooltip-content style="margin: 5px">' +
            '<div class="event-name" style="padding: 10px">' +
            '<i class="fas fa-circle" style="margin-right:12px;color:' +
            e.events[i].color +
            '"></i>' +
            e.events[i].name +
            '</div>' +
            '</div>';
        }
        if (this.tooltip != null) {
          this.tooltip.destroy();
          this.tooltip = null;
        }

        this.tooltip = tippy(e.element, {
          placement: 'right',
          content: '<h3>TAREAS DEL DÍA</h3>' + content,
          allowHTML: true,
          animation: 'shift-away',
          animateFill: false,
          plugins: [animateFill],
          arrow: true,
          theme: 'light'
        });
        this.tooltip.show();
      }
    },
    mouseOutDay: function() {
      if (this.tooltip !== null) {
        this.tooltip.destroy();
        this.tooltip = null;
      }
    },
    /// ANUAL FIN ////
    openPopup() {
      this.dialogPopup = true;
    },
    closePopup() {
      this.dialogPopup = false;
      this.$refs.eventoFormulario.clearDialog();
    },
    async viewDay({ date }) {
      this.focus = date;
      await this.cambiarTipo('day');
    },
    getEventColor(event) {
      return event.color;
    },
    async prev() {
      var inicio = Date.parse(this.currentYear + '-1-1');
      var fecha = Date.parse(this.$refs.calendar.$children[0].start);
      if (inicio < fecha) {
        await this.$refs.calendar.prev();
        await this.cargarTareas(this.$refs.calendar.$children[0].start, this.$refs.calendar.$children[0].end);
      }
    },
    async next() {
      var fin = Date.parse(this.currentYear + '-12-31');
      var fecha = Date.parse(this.$refs.calendar.$children[0].end);
      if (fecha < fin) {
        await this.$refs.calendar.next();
        await this.cargarTareas(this.$refs.calendar.$children[0].start, this.$refs.calendar.$children[0].end);
      }
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    async cargarTareas(fechaini, fechafin) {
      this.events = [];
      this.showLoading = true;
      const res = await this.tareaService.get().execResource('tareas', {
        fechaini: fechaini,
        fechafin: fechafin,
        semanas: '',
        bandera: false
      });
      if (res.length > 0) {
        console.log(res);
        res.forEach((el) => {
          this.events.push({
            idfichatecnicapy: el.idfichatecnicapy,
            compitem: el.compitem,
            item: el.item,
            itemid: el.itemid,
            idtarea: el.idtarea,
            idacta: el.idactareferencia,
            proyecto: el.proyecto,
            name: el.tarea,
            start: el.fechafin,
            end: el.fechafin,
            etapa: el.etapa,
            actividad: el.actividad,
            startDate: moment(el.fechafin),
            endDate: moment(el.fechafin),
            color: '#1D4B23',
            id: el.idfichatecnicapy,
            responsables: el.jsonresponsables,
            soporte: el.jsonsoporte
          });
        });
      } else {
        this.events = [];
      }

      this.showLoading = false;
    }
  },
  async created() {
    this.tareaService = this.$httpService(new TareaService(), this);
  }
};
</script>
